import React, { FC } from 'react';
import PostPreviewCard from '../PostPreviewCard';

interface PostsProps {
  posts: any[] | null;
}

const Posts: FC<PostsProps> = ({ posts }) => {
  return (
    <div>
      <div className="grid-row">
        {posts.map((post) => {
          const { node } = post;
          return (
            <div key={node.frontmatter.slug} className="grid-col-12">
              <PostPreviewCard article={node} />
            </div>
          );
        })}
      </div>
      {posts.length === 0 && (
        <h1>Nothing to see here, yet.</h1>
      )}
    </div>
  );
};

export default Posts;
