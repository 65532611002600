import React, { FC } from 'react';
import { Link } from 'gatsby';
import { format } from 'date-fns';
import readingTime from '../../utils/readingTime';
import slugify from '../../utils/slugify';
import * as styles from './PostPreviewCard.module.scss';

interface PostPreviewCardProps {
  article: any;
  journal?: boolean;
}

const PostPreviewCard: FC<PostPreviewCardProps> = ({
  article,
  journal = false,
}) => {
  return (
    <article>
      <div className={styles.card}>
        {/* {!journal && (
          <Link to={`/${slugify(article.frontmatter.category)}/`}>
            <h2 className={styles.category}>
              ↳ {article.frontmatter.category}
            </h2>
          </Link>
        )} */}
        <Link className={styles.link} to={`/${article.frontmatter.slug}/`}>
          <h2>{article.frontmatter.title}</h2>
        </Link>
        <p className={styles.info}>
          {format(new Date(article.frontmatter.date), 'dd MMM yyyy')} |
          <span className={styles.readingTime}>
            {readingTime(article.html)}
          </span>
        </p>
      </div>
    </article>
  );
};

export default PostPreviewCard;
