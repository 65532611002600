import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Posts from '../components/Posts';
import * as styles from './journalStyles.module.scss';

interface ArticlesProps {
  data: {
    articles: {
      edges: any[];
    };
  };
}

const ArticlesPage: FC<ArticlesProps> = ({ data }) => {
  const { articles } = data;
  const latestPostDates = articles.edges.map(
    (edge) => edge.node.frontmatter.date,
  );
  const latestPostDate = latestPostDates[0];
  return (
    <Layout>
      <SEO
        title="Articles"
        slug="articles"
        keywords={[
          `articles`,
          `archives`,
          `posts`,
          `oscar alsing articles`,
          `oscar alsing archives`,
          `oscar alsing posts`,
        ]}
        pageDateUpdated={latestPostDate}
      />
      <h1 className={styles.header}>Articles</h1>
      <Posts posts={articles.edges} />
    </Layout>
  );
};

export const latestPostsQuery = graphql`
  {
    articles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { regex: "/article|journal/" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            slug
            tags
            category
            date
          }
        }
      }
    }
  }
`;

export default ArticlesPage;
